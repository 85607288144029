<template>
  <div class="box">
    <!-- <div class="navbar">
      <div class="login" @click="login">登录</div>
    </div> -->
    <div class="bg">
      <img src="../../assets/bg.png" alt="bg">
    </div>
    <div class="search">
      <div class="tip">
        <h1>开启搜索！开启奇妙之旅！</h1>
      </div>
      <div class="inp-box">
        <div class="inputForm">
          <el-autocomplete v-model="from" prefix-icon="el-icon-location-outline"
            :fetch-suggestions="querySearchAsyncFormStartPort" placeholder="From" @select="handleSelectForStartPort" key="startPort"
            placement="top" highlight-first-item clearable></el-autocomplete>
        </div>
        <img height="20px" width="25px" src="@/assets/arrow.png" alt="" />
        <div class="inputForm">
          <el-autocomplete v-model="to" prefix-icon="el-icon-location-outline"
            :fetch-suggestions="querySearchAsyncFormEndPort" placeholder="To" @select="handleSelectForEndPort" 
            highlight-first-item placement="top" key="endPort" clearable></el-autocomplete>
        </div>
        <div class="cdcdcd">|</div>
        <div class="inputForm">
          <el-date-picker v-model="newTime" type="date" placeholder="Select Date" value-format="yyyy-MM-dd" >
          </el-date-picker>
        </div>
        <div class="query" @click="query">
          <img height="20px" width="20px" src="@/assets/query.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import { GetPort } from "@/api/query";
export default {
  name: "homeIndex",
  data() {
    return {
      from: "",
      to: "",
      fromId:'',
      toId:'',
      newTime: "",
      year: "",
      month: "",
      date: "",
      userIp: "",
      restaurants: [],
      state: "",
      searchParam: {
        type: 1,
        search: ''
      },
      startPort: [],
      endPort: []
    };
  },
  async mounted() {
    this.year = String(new Date().getFullYear());
    this.month = new Date().getMonth() + 1;
    this.date = new Date().getDate();
    this.newTime = `${this.year}-${this.month}-${this.date}`;
    let startPortRes = await GetPort(this.searchParam)
    let startPortData = startPortRes.data
    startPortData.map((item) => {
      this.startPort.push({
        value: `${item.area}-${item.showPort_Chinese}(${item.showPort_English})`,
        id: item.id
      })
    })
    this.searchParam.type = 2
    let endPortRes = await GetPort(this.searchParam)
    let endPortData = endPortRes.data
    endPortData.map((item) => {
      this.endPort.push({
        value: `${item.area}-${item.showPort_Chinese}(${item.showPort_English})`,
        id: item.id
      })
    })
  },
  methods: {
    async querySearchAsyncFormStartPort(queryString, cb) {
      let results = queryString ? this.startPort.filter(this.createStateFilter(queryString)) : this.startPort;
      setTimeout(() => {
        cb(results);
      }, 800 * Math.random());
    },
    querySearchAsyncFormEndPort(queryString, cb) {
      let results = queryString ? this.endPort.filter(this.createStateFilter(queryString)) : this.endPort;
      setTimeout(() => {
        cb(results);
      }, 800 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1
        );
      };
    },
    handleSelectForStartPort(item) {
      this.fromId = item.id
    },
    handleSelectForEndPort(item){
      this.toId = item.id
    },  
    query() {
      if((!this.fromId)|| (!this.toId) || (!this.newTime) ){
        return this.$message.error('请选择好起运港，目的港及时间')
      }
      this.$router.push({path:"/shipping",
        query:{
          fromId:this.fromId,
          toId:this.toId,
          newTime:this.newTime
        }
      });
    }
  },
};
</script>

<style scoped>
::v-deep .el-input__inner {
  height: 36px;
}

::v-deep .el-input__inner:hover {
  border: #0088ff solid 1px;
}

.box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* background-image: url('../../assets/working.svg');
  background-size: 100vw 100vh;
  background-position: 0 0; */
  /* background-color: green; */
  background: -webkit-linear-gradient(right, #4284db, #29eac4);
  z-index: 1;
  position: relative;
}

.navbar {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.login {
  position: relative;
  top: 30px;
  right: 50px;
  background-color: #eff1f6;
  border: #eff1f6 solid 1px;
  height: 25px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.search {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-top: 200px;
  flex-wrap: wrap;
}

.inp-box {
  width: 1200px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  background-color: aliceblue;
  align-items: center;
}

.inputForm {
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: center;
}

.cdcdcd {
  color: #cdcdcd;
  margin-left: 12.5px;
  margin-right: 12.5px;
}

.query {
  height: 36px;
  width: 60px;
  background-color: #0088ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}

.bg {
  width: 480px;
  height: 420px;
  position: absolute;
  z-index: -1;
  right: calc((100% - 1200px)/2);
  top: 52px;

}

.bg img {
  width: 100%;
  height: 100%;
}

.tip {
  width: 1200px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  color: #f0f8ff;
}
</style>