<template>
  <div class="box">
    <div class="nav">
      <div class="from">
        <div class="title">装运地</div>
        <div class="input-box1">
          <el-autocomplete v-model="from" prefix-icon="el-icon-location-outline"
            :fetch-suggestions="querySearchAsyncFormStartPort" placeholder="From" @select="handleSelectForStartPort"
            key="startPort" placement="bottom" highlight-first-item clearable></el-autocomplete>
        </div>
      </div>
      <div>
        <img class="switch" src="@/assets/switch.png" />
      </div>
      <div class="from">
        <div class="title">目的地</div>
        <div class="input-box2">
          <el-autocomplete v-model="to" prefix-icon="el-icon-location-outline"
            :fetch-suggestions="querySearchAsyncFormEndPort" placeholder="To" @select="handleSelectForEndPort"
            highlight-first-item placement="bottom" key="endPort" clearable></el-autocomplete>
        </div>
      </div>
      <div class="From datePicker">
        <div class="title">装载日期</div>
        <el-date-picker v-model="pageParam.newTime" type="date" placeholder="请输入日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
      <div class="query" @click="search()">
        <img height="18px" width="18px" src="@/assets/query.png" alt="" />
      </div>
    </div>
    <div class="dataList" v-show="list.length == 0">
      <img src="../../assets/blank.svg" alt="没有更多了" style="width: 620px;height:620px;">
    </div>
    <div class="dataList" v-show="list.length > 0">
      <div class="list" v-for="(item, index) in list" :key="item.id">
        <div class="left">
          <div class="date">{{ getDaysBetweenDates(item.startPort_DEP, item.endPort_DEP) }} DAY(S)</div>
          <div class="detail">
            <i class="el-icon-date"></i>
            <div class="from-det">
              <div class="center" style="width: 100%">
                <p style="margin-top: 0;">{{ item.startPort }}</p>
              </div>
              <div style="margin-left: 18px;font-size: 14px;">{{ formatDate(item.startPort_DEP) }}</div>
            </div>
            <div class="line">
              — — — — — — — —<i class="el-icon-ship"></i>— — — — — — — — →
            </div>
            <i class="el-icon-date"></i>
            <div class="from-det">
              <div class="center" style="width: 100%">
                <p style="margin-top: 0;">{{ item.endPort }}</p>
              </div>
              <div style="margin-left: 18px;font-size: 14px;" class="center">{{ formatDate(item.endPort_DEP) }}</div>
            </div>
          </div>
        </div>
        <div style="border-right: 1px solid grey; height: 100px"></div>
        <div class="right">
          <div class="ship" style="width: 100%">
            <div class="center" style="width: 100%;margin-bottom: 8px;color: #7f7f7f;">船名/航次</div>
            <div class="center" style="width: 100%">{{ item.vesselName + '/' + item.voyage }}</div>
          </div>
          <div class="ship" style="width: 100%">
            <div class="center" style="width: 100%;margin-bottom: 8px;color: #7f7f7f;">共舱船公司(航线代码)</div>
            <div class="center" style="width: 100%;font-size: 11px;">{{ `${item.vesselSharing}` }}</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="pageTotal" :page-size="10"
          @current-change="currentPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GetPort, GetQueryDetail } from "@/api/query";
export default {
  data() {
    return {
      searchParam: {
        type: 1,
        search: ''
      },
      startPort: [],
      endPort: [],
      from: "",
      to: "",
      pageTotal: 0,
      pageParam: {
        fromId: '',
        toId: '',
        currentPage: 1,
        newTime: "",
      },
      list: []
    };
  },
  methods: {
    currentPage(e) {
      console.log(e)
      this.pageParam.currentPage = e
      this.search()
    },
    async queryDetail(param) {
      let GetQueryDetailRes = await GetQueryDetail(param)
      return GetQueryDetailRes
    },
    async search() {
      console.log(this.pageParam.fromId, this.pageParam.toId, this.pageParam.newTime)
      const res = await this.queryDetail(this.pageParam)
      this.list = res.data.list
      this.pageTotal = res.data.total
    },
    async querySearchAsyncFormStartPort(queryString, cb) {
      let results = queryString ? this.startPort.filter(this.createStateFilter(queryString)) : this.startPort;
      setTimeout(() => {
        cb(results);
      }, 800 * Math.random());
    },
    querySearchAsyncFormEndPort(queryString, cb) {
      let results = queryString ? this.endPort.filter(this.createStateFilter(queryString)) : this.endPort;
      setTimeout(() => {
        cb(results);
      }, 800 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1
        );
      };
    },
    handleSelectForStartPort(item) {
      this.pageParam.fromId = item.id
    },
    handleSelectForEndPort(item) {
      this.pageParam.toId = item.id
    },

  },
  watch: {
    '$route'(newValue, old) {
      console.log(newValue, old)
    },
    deep: true
  },
  async created() {
    // console.log(this.$route.query)
    this.pageParam.fromId = Number(this.$route.query.fromId)
    this.pageParam.toId = Number(this.$route.query.toId)
    this.pageParam.newTime = this.$route.query.newTime
    let startPortRes = await GetPort(this.searchParam)
    let startPortData = startPortRes.data
    startPortData.map((item) => {
      this.startPort.push({
        value: `${item.area}-${item.showPort_Chinese}(${item.showPort_English})`,
        id: item.id
      })
    })
    this.startPort.map(item => {
      if (item.id == this.pageParam.fromId) {
        this.from = item.value
      }
    })
    this.searchParam.type = 2
    let endPortRes = await GetPort(this.searchParam)
    let endPortData = endPortRes.data
    endPortData.map((item) => {
      this.endPort.push({
        value: `${item.area}-${item.showPort_Chinese}(${item.showPort_English})`,
        id: item.id
      })
    })
    this.endPort.map(item => {
      if (item.id == this.pageParam.toId) {
        this.to = item.value
      }
    })
    await this.search()
  },
  async mounted() {

  },
  computed: {
    formatDate() {
      return (e) => {
        let date = new Date(e)
        var yyyy = date.getFullYear();
        var mm = date.getMonth() + 1; // getMonth() is zero-based  
        var dd = date.getDate();
        var hh = date.getHours();
        var MM = date.getMinutes();
        var ss = date.getSeconds();
        // Padding zeros if necessary  
        mm = mm < 10 ? '0' + mm : mm;
        dd = dd < 10 ? '0' + dd : dd;
        // hh = hh < 10 ? '0' + hh : hh;
        // MM = MM < 10 ? '0' + MM : MM;
        // ss = ss < 10 ? '0' + ss : ss;
        return yyyy + '/' + mm + '/' + dd
      }
    },
    getDaysBetweenDates() {
      return (e1, e2) => {
        const date1 = new Date(e1)
        const date2 = new Date(e2)
        const oneDay = 24 * 60 * 60 * 1000; // 一天有24小时，每小时60分钟，每分钟60秒，每秒1000毫秒  
        const differenceInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(differenceInMilliseconds / oneDay);
      }

    }
  }
};
</script>

<style scoped>
::v-deep .el-input__inner {
  height: 40px;
}

/* 起运港选择框样式 */
::v-deep .el-select .el-input__inner {
  height: 36px;
  width: 170px;
  border: 0;
  color: black;
}

/* 目的港选择框样式 */
::v-deep .from-det .el-input__inner {
  height: 36px;
}

.box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f7f7f7;
  align-content: flex-start;
  height: auto !important;
  padding: 40px 0 40px 0;
}

.navbar {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.nav {
  height: 120px;
  width: 1220px;
  border-radius: 10px 10px 0 0;
  background-color: rgb(233, 240, 247);
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.input-box {
  position: relative;
  width: 200px;
  /* 设置输入框的宽度 */
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
}

.from {
  display: flex;
  flex-wrap: wrap;
  width: 285px;
  justify-content: center;
}

.input-box1 input {
  background-image: url("@/assets/A.png");
  /* 设置背景图像为图标 */
  font-size: 14px;
  border: #eff1f6 solid 1px;
  border-radius: 5px;
  height: 36px;
  padding-left: 28px;
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 12px 12px;
  color: #606266;
}

.input-box2 {
  /* margin-right: 40px; */
}

.input-box2 input {
  background-image: url("@/assets/B.png");
  /* 设置背景图像为图标 */
  font-size: 14px;
  border: #eff1f6 solid 1px;
  border-radius: 5px;
  height: 36px;
  padding-left: 28px;
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 12px 12px;
  color: #606266;
}

input:focus {
  border-color: #007bff;
  /* 获取焦点时的边框颜色 */
  outline: none;
  /* 移除默认的聚焦轮廓 */
}

.switch {
  height: 22px;
  width: 24px;
  border: #007bff solid 1px;
  background-color: white;
  border-radius: 50%;
  margin-top: 35px;
  position: relative;
}

.query {
  height: 36px;
  width: 80px;
  background-color: #0088ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}

.dataList {
  height: auto;
  width: 1220px;
  background-color: #f8f9fd;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  border-radius: 0 0 10px 10px;
}

.list {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  height: 140px;
  width: 1150px;
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); */
}

.date {
  width: 90%;
  display: flex;
  justify-content: center;
}

.detail {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.from-det {
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-content: center; */
  font-size: 14px;
}

.line {
  width: 466px;
  font-weight: 200;
  color: grey;
  margin: 0 15px;
}

.ship {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.left {
  height: 140px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.right {
  height: 140px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
}

.el-icon-ship {
  font-size: 25px;
}

.pagination {
  width: 100%;
  height: 80px;
  /* background-color: #007bff; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>