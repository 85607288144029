<template>
  <div id="app">
    <keep-alive>
      <navigation />
      
    </keep-alive>
    <transition :name="transitionName">
          <router-view class="pages" />
    </transition>
    <keep-alive>
      <pageFooter/>
    </keep-alive>
  </div>
</template>
<script>
import navigation from "@/components/navigation.vue";
import pageFooter from "@/components/footer.vue";
export default {
  data() {
    return {
      transitionName: 'slide-left'
    }
  },
  components: {
    navigation,
    pageFooter
  },
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      // 有主级到次级
      // console.log(this.transitionName)
      // if (to.meta.index > from.meta.index) {
      //   this.transitionName = 'slide-left' // 向左滑动
      // } else if (to.meta.index < from.meta.index) {
      // 由次级到主级
      this.transitionName = 'slide-right'
      // } else {
      //   this.transitionName = ''   //同级无过渡效果
      // }
    }
  }
}
</script>
<style>
#app {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
}

body {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  overflow-x: hidden;
}

.pages:scroll {
  width: calc(100vw - 15px) !important;
  height: calc(100vh - 60px) !important;
  margin-top: 60px;
}
.pages {
  width: calc(100vw) !important;
  height: calc(100vh - 60px) !important;
  margin-top: 60px;
}

.scrollable-container {
  width: 100%;
  /* 容器宽度 */
  height: 200px;
  /* 容器高度 */
  padding-right: 15px;
  /* 右内边距减去滑动条的宽度 */
  overflow-y: scroll;
  /* 垂直滚动条 */
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
