<template>
    <div class="Nav">
        <div class="Nav-connet">
            <div class="Nav-connet-logo" @click="Tohome()">
                <img src="../assets/logo.png" alt="logo" style="height:60px;width:auto;">
                <h4>准班船期</h4>
            </div>
            <div class="Nav-connet-nav">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="2">首页</el-menu-item>
                    <el-menu-item index="1">联系我们</el-menu-item>
                </el-menu>
            </div>
            <div class="login">
                <el-button type="text" @click="ToLogin">登录/注册</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: "2"
        }
    },
    components: {

    },
    computed: {

    },
    watch: {

    },
    methods: {
        handleSelect(e) {
            this.activeIndex = e
            console.log(this.activeIndex)
            switch (this.activeIndex) {
                case '1':
                    this.$message({
                        message: '暂未开放',
                        type: 'warning'
                    });
                    break;
                case '2':
                    this.$router.push({ path: '/' })
                    break;
                default:
                    break;
            }
        },
        ToLogin() {
            this.$message({
                message: '暂未开放',
                type: 'warning'
            });
        },
        Tohome() {
            this.$router.push({ path: '/' })
            this.activeIndex = ''
        }
    },
    created() {

    },
    mounted() {

    },
}
</script>

<style>
.Nav {
    width: 100vw;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 999;
    background-color: white;
}

.Nav-connet {
    width: 80%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Nav-connet-logo {
    width: 180px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Nav-connet-logo h4 {
    font-weight: 100;
    font-size: 24px;
}

.login {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Nav-connet-nav {
    width: calc(100% - 220px);
    height: 100%;
}

.el-menu {
    float: right;
}

.el-button--text {
    color: #303133;
}
</style>
