<template>
    <div class="bottom">
        <div class="bottom-title">
            <div v-for="item in bottomTiltes" :key="item.index">{{ item.title }}</div>
        </div>
        <!-- <div class="bottom-con">
            <div class="bot-con-1">
                <div v-for="item in botttomCon1" :key="item">{{ item }}</div>
            </div>
        </div> -->
        <div class="bottom-bot">
            <img :src="record[0].img1" alt="">
            <img :src="record[0].img2" alt="">
            <div>{{ record[0].title1 }}</div>
            <div>{{ record[0].title2 }}</div>
        </div>
    </div>
</template>
  
<script scoped>
export default {
    data() {
        return {
            from: "",
            to: "",
            newTime: "",
            year: "",
            month: "",
            date: "",
            userIp: "",
            restaurants: [],
            state: "",
            timeout: null,
            bottomTiltes: [{
                title:'法律声明',
                index:1,
                url:''
            },{
                title:'联系我们',
                index:2,
                url:''
            }],
            // botttomCon1: ['阿里巴巴集团', '淘宝网', '天猫', '全球速卖通', '阿里巴巴国际交易市场', '1688', '阿里妈妈', '飞猪', '阿里云计算', 'AliOS', '万网', '高德', 'UC', '友盟', '优酷', '钉钉', '支付宝', '达摩院', '淘宝海外', '阿里云盘', '饿了么'],
            record: [
                {
                    img1: 'https://gw.alicdn.com/tfs/TB1GxwdSXXXXXa.aXXXXXXXXXXX-65-70.gif',
                    img2: 'https://img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png',
                    title1: '浙公网安备 33010602009975号',
                    title2: '浙B2-20080101-4',
                    url:'/'
                }
            ]
        };
    },
    mounted() {
    },
    methods: {

    },
};
</script>
  
<style scoped>
.bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* border-radius: 10px 10px 0 0; */
    min-width: 750px;
    background-color: white;
    /* z-index: 999; */
    padding-top: 8px;
    padding-bottom: 8px;
    /* position: absolute;
    bottom: 0; */
}

.bottom-title {
    margin-top: 10px;
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    color: black;
}

.bottom-title div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-right: 35px;
    color: black;
}

.bottom-con {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 130px;
}

.bot-con-1 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 10px;
}

.bot-con-1 div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-right: 10px;
    color: #6d7071;
}

.bot-con-2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.bot-con-2 div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-right: 10px;
    color: #6d7071;
}

.bottom-bot {
    height: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6d7071;
    font-size: 10px;
    margin-bottom: 8px;
}

.bottom-bot img {
    margin-right: 12px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-bot div {
    color: #6d7071;
}</style>