import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/home.vue'
import Login from '@/views/login/login.vue'
import Shipping from '@/views/shipping/shipping.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:Home,
    component: Home
  },
  {
    path: '/index',
    name:Home,
    component: Home
  },
  {
    path: '/home',
    name:Home,
    component: Home
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/shipping',
    component: Shipping
  }
]
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
