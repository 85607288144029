import request from '@/utils/request'

export function GetPort(data) {
  return request({
    url: `/query/GetPort`,
    method: 'post',
    data
  })
}
export function GetQueryDetail(data) {
    return request({
      url: `/query/query`,
      method: 'post',
      data
    })
  }