import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers.Authorization = store.getters.token
      return config
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.data instanceof Blob) return response.data // 返回了Blob对象
    const res = response.data
    if (res.code == 203) {
       Message({
        message: res.msg || 'Error',
        type: 'error'
      })
      return Promise.reject(res.message)
    } else if( res.code == 202 ) {
      return Message({
        message: res.msg || '成功',
        type:'success'
      })
    }else if(res.code == 200){
      return res
    }
  },
  error => {
    if (error.response && error.response.status === 401) {
      // 在这里可以执行重定向、清除登录状态等操作
      Message({ type: 'error', message: error.response.data.msg })
      // 说明 token 超时了
      store.dispatch('user/logout') // 调用 action 退出登录
      // 主动跳到登录页
      router.push('/login') // 跳转到登录页
      return Promise.reject(error.response.data.msg)
    } else {
      Message({
        message: error.response.data.msg || 'error',
        type: 'error'
      })
      return Promise.reject(error)
    }
  }
)

export default service
