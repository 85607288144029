<template>
	<div class="center">
		<!-- <h1>注册/登陆</h1> -->
		<div class="logon">
			<div :class="overlaylong">
				<div class="overlaylong-Signin" v-if="disfiex == 0">
					<h2 class="overlaylongH2">登录</h2>
					<el-input v-model="username" placeholder="手机号或邮箱"></el-input>
					<el-input v-model="password" show-password placeholder="请输入密码"></el-input>
					<span style="font-size: 12px;color: #7f7f7f; ">忘记密码?</span>
					<button class="inupbutton">登录</button>
				</div>
				<div class="overlaylong-Signup" v-if="disfiex == 1">
					<h2 class="overlaylongH2">注册</h2>
					<el-input v-model="username" placeholder="手机号或邮箱"></el-input>
					<el-input v-model="password" show-password placeholder="请输入密码"></el-input>
					<button class="inupbutton">注册</button>
				</div>
			</div>
			<div :class="overlaytitle">
				<div class="overlaytitle-Signin" v-if="disfiex == 0">
					<h2 class="overlaytitleH2">欢迎回来!</h2>
					<p class="overlaytitleP">
						请先登录，踏上你的奇妙之旅
					</p>
					<div class="buttongohs" @click="Signin">注册</div>
				</div>
				<div class="overlaytitle-Signup" v-if="disfiex == 1">
					<h2 class="overlaytitleH2">您好，朋友!</h2>
					<p class="overlaytitleP">
						请注册您的个人信息，让我们一起探索里面的内容
					</p>
					<div class="buttongohs" @click="Signup">登陆</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "loginIndex",
	data() {
		return {
			overlaylong: 'overlaylong',
			overlaytitle: 'overlaytitle',
			disfiex: 0,
			username:'',
			password:''
		}
	},
	methods: {
		Signin() {
			this.overlaylong = "overlaylongleft"
			this.overlaytitle = "overlaytitleright"
			setTimeout(() => {
				this.disfiex = 1
			}, 200)
		},
		Signup() {
			this.overlaylong = "overlaylongright"
			this.overlaytitle = "overlaytitleleft"

			setTimeout(() => {
				this.disfiex = 0
			}, 200)

		}
	}
};
</script>

<style scoped>
.center {
	width: 100%;
	height: 100%;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background: linear-gradient(135deg, #001f3f, #0088a9, #00c9a7, #92d5c6, #ebf5ee);

}

h1 {
	font-size: 30px;
	color: black;
}

.logon {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	/* position: relative;
		overflow: hidden; */
	width: 768px;
	max-width: 100%;
	min-height: 480px;
	min-width: 550px;
	margin-top: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: -webkit-linear-gradient(right, #4284db, #29eac4);
	/* background-color: #0088ff; */
}

.overlaylong {
	border-radius: 10px 0 0 10px;
	width: 50%;
	height: 480px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlaylongleft {
	border-radius: 0px 10px 10px 0px;
	width: 50%;
	height: 100%;
	background-color: #fff;
	transform: translateX(100%);
	transition: transform 0.6s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlaylongright {
	border-radius: 10px 0 0 10px;
	width: 50%;
	height: 100%;
	background-color: #fff;
	transform: translateX(0%);
	transition: transform 0.6s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlaytitle {
	border-radius: 0px 10px 10px 0px;
	width: 50%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
}


.overlaytitleH2 {
	font-size: 30px;
	color: #fff;
	margin-top: 20px;
}

.overlaytitleP {
	font-size: 15px;
	color: #fff;
	margin-top: 20px;
}

.overlaytitleleft {
	border-radius: 0px 10px 10px 0px;
	width: 50%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateX(0%);
	transition: transform 0.6s ease-in-out;
}

.overlaytitleright {
	border-radius: 0px 10px 10px 0px;
	width: 50%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateX(-100%);
	transition: transform 0.6s ease-in-out;
}

.overlaytitle-Signin {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.overlaytitle-Signup {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.buttongohs {
	width: 180px;
	height: 40px;
	border-radius: 50px;
	border: 1px solid #fff;
	color: #fff;
	font-size: 15px;
	text-align: center;
	line-height: 40px;
	margin-top: 40px;
}

.overlaylongH2 {
	font-size: 25px;
	color: black;
	/* width: 250px; */
}

.overlaylong-Signin {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 480px;
}

.overlaylong-Signup {
	height: 480px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.el-input {
	background-color: #eee;
	border: none;
	/* padding: 12px 15px; */
	margin: 10px 0;
	width: 240px;
	overflow: hidden;
}

h3 {
	font-size: 10px;
	margin-top: 10px;
	cursor: pointer;
}

.inupbutton {
	background-color: #29eac4;
	/* background-color: #0088ff; */
	border: none;
	width: 180px;
	height: 40px;
	border-radius: 50px;
	font-size: 15px;
	color: #fff;
	text-align: center;
	line-height: 40px;
	margin-top: 30px;
	margin-bottom: 10px;
}
</style>